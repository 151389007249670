import React from "react";
import "./Footer.css"; 
const Footer = () => {
  return (
    <footer className="footer">
     
      <div className="disclaimer">
        <p className="text-center mb-0">
          Todas las cotizaciones, índices y cualquier otro valor publicadas en
          este sitio web son a fines informativos y tienen un carácter
          orientativo, por lo que deben ser tomados únicamente a modo de
          referencia.
        </p>
        <p className="text-center mb-0">
          En ningún caso se debe interpretar o suponer una recomendación de
          compra o venta.
        </p>
      </div>
      <div className="rights text-center">
        <p>
          Todos los derechos reservados &copy;{" "}
          <a href="https://sftdevelopment.com" className="footer-link">
            SFT DEV
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
