import React from 'react'
import Logo from '../logo2.png'
export const By = () => {
  return (
    <a href='https://sftdevelopment.com' className="by">
    <p>Power by</p>
    <img src={Logo} alt="sftdevelopment" />
  </a>
  )
}
export default By;