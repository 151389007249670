import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import "./Dolar.css";

const Dolar = () => {
  const [dolares, setDolares] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [amount, setAmount] = useState("");
  const [option, setOption] = useState("compra");
  const [result, setResult] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedDolar, setSelectedDolar] = useState(null);
  const [calculating, setCalculating] = useState(false);
  const [conversionType, setConversionType] = useState("dolaresAPesos"); // Por defecto, la conversión es de dólares a pesos

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://dolarapi.com/v1/dolares");
        const currentDateTime = new Date().toLocaleString("es-AR");
        setLastUpdated(currentDateTime);
        setDolares(response.data);
        setCountdown(300);
      } catch (error) {
        console.error("Error fetching dolar data:", error);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          fetchData();
          return 300;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minutos ${remainingSeconds} segundos`;
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleConvert = async () => {
    if (amount === "") {
      setResult("Ingrese una cantidad válida.");
      return;
    }

    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) {
      setResult("Ingrese una cantidad válida.");
      return;
    }

    const dolarVenta = selectedDolar ? selectedDolar.venta : 115.5; // Precio de venta en el ejemplo
    const dolarCompra = selectedDolar ? selectedDolar.compra : 109.5; // Precio de compra en el ejemplo

    setCalculating(true);

    let convertedAmount;
    if (conversionType === "dolaresAPesos") {
      if (option === "compra") {
        convertedAmount = parsedAmount * dolarCompra;
      } else {
        convertedAmount = parsedAmount * dolarVenta;
      }
      setResult(`$${convertedAmount.toLocaleString("es-AR")} ARS.`);
    } else {
      // Si la conversión es de pesos a dólares
      if (option === "compra") {
        convertedAmount = parsedAmount / dolarCompra;
      } else {
        convertedAmount = parsedAmount / dolarVenta;
      }
      setResult(` $${convertedAmount.toLocaleString("es-AR")} USD.`);
    }
    setCalculating(false);
  };

  const handleOpenModal = (dolar) => {
    setSelectedDolar(dolar);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="app-container">
      <div className="content-container">
        <div className="alert">
          <p className="update-time">Última actualización: {lastUpdated}</p>
          <p className="next-update-time">
            Cotizacion del Dolar Día a Día <br /> Dolar Oficial - Dolar Blue -
            Dolar Bolsa - Dolar CCL - Dolar Mayorista - Dolar Cripto.
          </p>
        </div>

        <p className="next-update-time">
          Próxima actualización en: {formatTime(countdown)}
        </p>
        <Container fluid>
          <Row xs={1} md={2} lg={3} xl={4} xxl={5} className="g-4">
            {dolares.map((dolar, index) => (
              <Col
                key={index}
                xs={12}
                className="d-flex justify-content-center"
              >
                <Card className="custom-card" style={{ width: "250px" }}>
                  <Card.Body className="card-body">
                    <Card.Title className="card-title">
                      {" "}
                      💶 Dolar {dolar.nombre}
                    </Card.Title>
                    <Card.Text className="card-text">
                      <div className="d-flex justify-content-center">
                        <div className="me-2">Compra:</div>
                        <div>${dolar.compra.toFixed(2)}</div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="me-2">Venta:</div>
                        <div>${dolar.venta.toFixed(2)}</div>
                      </div>
                    </Card.Text>
                    <Button
                      variant="success"
                      className="custom-button"
                      onClick={() => handleOpenModal(dolar)}
                      disabled={calculating}
                    >
                      {calculating ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Calcular"
                      )}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header
            closeButton
            style={{ backgroundColor: "black", color: "white" }}
          >
            <Modal.Title>{`Calcular valor dolar ${
              selectedDolar && selectedDolar.nombre
            }`}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "black", color: "white" }}>
            <Form>
              <Form.Group className="mb-3" controlId="amount">
                <Form.Label>
                  {conversionType === "dolaresAPesos"
                    ? "Cantidad de dólares:"
                    : "Cantidad de pesos:"}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={
                    conversionType === "dolaresAPesos"
                      ? "Ingrese la cantidad de dólares"
                      : "Ingrese la cantidad de pesos"
                  }
                  value={amount}
                  onChange={handleAmountChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="option">
                <Form.Label>Opción:</Form.Label>
                <div className="d-flex">
                  <Button
                    variant={
                      option === "compra" ? "success" : "outline-success"
                    }
                    className="me-2"
                    onClick={() => setOption("compra")}
                  >
                    Compra
                  </Button>
                  <Button
                    variant={option === "venta" ? "success" : "outline-success"}
                    onClick={() => setOption("venta")}
                  >
                    Venta
                  </Button>
                </div>
              </Form.Group>

              <div className="d-flex align-items-center">
                <Button
                  variant="success"
                  onClick={handleConvert}
                  disabled={calculating}
                >
                  {calculating ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Calcular"
                  )}
                </Button>
                {result && <p className="ms-2 mb-0">Res.: {result}</p>}
              </div>
            </Form>
            <Button
              variant="success mt-3"
              onClick={() =>
                setConversionType((prevType) =>
                  prevType === "dolaresAPesos"
                    ? "pesosADolares"
                    : "dolaresAPesos"
                )
              }
            >
              {conversionType === "dolaresAPesos"
                ? "Convertir a pesos"
                : "Convertir a dólares"}
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Dolar;
