import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dolar from "./components/Dolar";
import Footer from "./components/Foooter"; // Corregido el nombre del componente Footer
import Header from "./components/Header";
import By from "./components/By";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <div className="container">
        <Routes>
          <Route path="/" element={<Dolar />} />
        </Routes>
        <By />
      </div>
      <Footer />
    </Router>
  );
}

export default App;
