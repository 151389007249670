import React, { useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import Logo from "../dolarIcon.png";
import "./Header.css";

const Header = () => {
  const [expanded] = useState(false);


  return (
    <Navbar
      bg="#000"
      variant="dark"
      expand="lg"
      expanded={expanded}
      className="header"
    >
      <Container fluid>
        <Navbar.Brand className="logoContent" href="/">
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "50px" }}
            className="logo"
          />
          <h1>DOLARITOAR</h1>
        </Navbar.Brand>
       
       
      </Container>
    </Navbar>
  );
};

export default Header;
